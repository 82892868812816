<template>
    <div>
        <directCasoDialogo></directCasoDialogo>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';

    import directCasoDialogo from '@/components/Caso/DirectCasoDialogo';

    export default {
        name: 'caso',
        data() {
            return {
            }
        },
        watch: {
            "$route.params.id": {
                handler: function () {
                    this.getByGuid(this.$route.params.id);
                },
                deep: true,
                immediate: true,
            },
        },
        components: {
            directCasoDialogo
        },

        methods: {
            ...mapActions('caso', [
                'getByGuid'
            ]),

        },

        mounted() {
        }
    }
// https://localhost:5001/#/DirectCaso/49440490-a23e-45c7-aa12-4d96f47f5b14
</script>