<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="getGrant('0103')"
                       dark
                       rounded
                       color="error"
                       @click="copyGuid">
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn v-if="this.modificado"
                       dark
                       rounded
                       small
                       color="error"
                       @click="saveItem">
                    Guarda
                </v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text v-if="item.estado != undefined">
            <v-container grid-list-md fluid>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md2 class="mt-3">
                            <v-chip :text-color="estadoTextColor"
                                    :color="estadoColor"
                                    small
                                    dark>
                                {{ estadoNombre }}
                            </v-chip>
                        </v-flex>

                        <v-flex xs12 md1>
                            <selectUser v-model="item.applicationUser.id"
                                        :texto="item.applicationUser.userName"
                                        :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0101')) || !getGrant('0103')"
                                        label="A cargo"
                                        @change="change">
                            </selectUser>
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <selectCliente v-model="item.cliente.id"
                                           :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0101')) || !getGrant('0103')"
                                           :texto="item.cliente.nombre"
                                           @change="change">
                                @texto="item.cliente.nombre = $event">
                            </selectCliente>
                        </v-flex>

                        <v-flex xs12 sm6 md1>
                            <v-text-field v-model="item.nombre"
                                          required
                                          :rules="[v => !!v || 'Obligatorio']"
                                          :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0101')) || !getGrant('0103')"
                                          label="Dominio"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm3 md1>
                            <v-text-field v-model="item.siniestro"
                                          required
                                          :rules="[v => !!v || 'Obligatorio']"
                                          :disabled="(item.estadoId>ESTADOS.INICIADO && !getGrant('0101')) || !getGrant('0103')"
                                          label="Siniestro"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <fecha v-model="item.fechaSiniestro"
                                   :disabled="!getGrant('0103')"
                                   label="Fecha Siniestro"
                                   @change="change">
                            </fecha>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <autocompletaTitular v-model="item.titular"
                                                 :disabled="!getGrant('0103')"
                                                 titulo="Titular"
                                                 @change="change">
                            </autocompletaTitular>
                        </v-flex>

                        <v-flex xs12 md3>
                            <infoAuto class="pt-2"
                                      :item="item"
                                      :disabled="!getGrant('0103')"
                                      @change="changeMarcaModelo">
                            </infoAuto>
                        </v-flex>

                        <v-flex xs6 sm1>
                            <v-text-field v-model="item.anio"
                                          :disabled="!getGrant('0103')"
                                          label="Año"
                                          @change="change">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs6 sm3 md2>
                            <selectTramitador v-model="item.tramitador.id"
                                              :disabled="!getGrant('0103')"
                                              :texto="item.tramitador.nombre"
                                              :clienteId="item.cliente.id"
                                              @change="change"
                                              @texto="item.tramitador.nombre = $event">
                            </selectTramitador>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <autocompletaProductor v-model="item.productor"
                                                   :disabled="!getGrant('0103')"
                                                   titulo="Productor"
                                                   :clienteId="item.cliente.id"
                                                   @change="change" />
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <selectTipobaja v-model="item.tipoBaja.id"
                                            :disabled="!getGrant('0103')"
                                            :texto="item.tipoBaja.nombre"
                                            @change="change"
                                            @texto="settipobaja($event)">
                            </selectTipobaja>
                        </v-flex>

                        <v-flex xs12 sm2>
                            <informacion titulo="Desarmadero"
                                         :color="desarmaderoColor"
                                         dark="dark"
                                         :texto="item.desarmadero.nombre">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm2>
                            <informacion titulo="Gestor"
                                         :texto="item.gestor.nombre">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm3>
                            <informacion titulo="Registro"
                                         :texto="item.registro.nombre">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm2>
                            <informacion titulo="Estado del informe"
                                         :color="informeColor"
                                         dark="dark"
                                         :texto="item.estadoInforme.nombre">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm1>
                            <informacion titulo="Deuda Pat."
                                         :texto="fmtDouble(item.deudaPatente)">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm1>
                            <informacion titulo="D. Multa"
                                         :texto="fmtDouble(item.deudaMulta)">
                            </informacion>
                        </v-flex>

                        <v-flex xs12 sm1>
                            <dropboxLink v-model="item.dropboxLink"
                                         :edit="getGrant('0103')"
                                         @change="change" />
                        </v-flex>

                        <v-flex xs12 ms3>
                            <casoTramite></casoTramite>
                        </v-flex>

                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>
        <v-dialog v-model="cierraDialog" max-width="300px">
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dense color="yellow">
                        <v-toolbar-title>
                            Hay cambios pendientes...
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pa-10"> </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="cancela">
                        Cancela
                    </v-btn>
                    <v-btn color="error" @click="save">
                        Guarda
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import autocompletaProductor from '@/components/Productor/autocompletaProductor';
    import autocompletaTitular from '@/components/Titular/autocompletaTitular';
    import casoTramite from '@/components/Caso/CasoTramite';
    import dropboxLink from '@/components/Tools/DropboxLink';
    import infoAuto from '@/components/Tools/InfoAuto/InfoAuto';
    import informacion from '@/components/Tools/Informacion';
    import fecha from '@/components/Tools/Fecha';
    import selectCliente from '@/components/Selects/selectCliente';
    import selectTipobaja from '@/components/Selects/selectTipoBaja';
    import selectTramitador from '@/components/Selects/selectTramitador';
    import selectUser from '@/components/Selects/selectUser';

    export default {
        name: 'casoDialogo',
        data() {
            return {
                cierraDialog: false,
                rules: [
                    value => !!value || 'Required.',
                    value => (value || '').length <= 7 || 'Max 20 characters',
                    value => {
                        //const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        const pattern = "[A - Za - z][0 - 9]";
                        return pattern.test(value) || 'Invalid e-mail.';
                    }
                ]
            };
        },

        methods: {
            ...mapActions('caso', ['closeDialog', 'saveItem', 'setModificado','initializeDirect']),

            fmtDouble(val) {
                if (val == '' || val == null) {
                    return '$ 0.00';
                }
                return '$ ' + Number(val).toLocaleString();
            },

            copyGuid() {
                /* Copy the text inside the text field */
                let s = window.location.href;
                let ix = s.indexOf("/#/");

                navigator.clipboard.writeText(s.substring(0, ix) + '/#/DirectCaso/' + this.item.guid);
            },

            change() {
                this.setModificado(true);
            },

            changeMarcaModelo(item) {
                this.item.marca = item.marca;
                this.item.marcaId = item.marca.id;
                this.item.modelo = item.modelo;
                this.item.modeloId = item.modelo.id;
                this.item.modeloValor = item.modeloValor;
                this.item.modeloValorId = item.modeloValor.modeloValorId;
                this.change();
            },

            settipobaja(tipobaja) {
                this.item.tipoBaja = tipobaja;
            },

            close() {
                if (!this.loggedUser.loggedIn)
                    this.$router.push('/');

                if (this.modificado) this.cierraDialog = true;
                else this.closeDialog();
            },

            save() {
                this.saveItem();
                this.cancela();
            },

            getGrant(grant) {
                return this.loggedUser.grants.indexOf(grant) >= 0;
            },

            cancela() {
                this.cierraDialog = false;
                this.closeDialog();
            }
        },

        computed: {
            ...mapState(['TRAMITES', 'ESTADOS']),
            ...mapState('caso', ['item', 'id', 'modificado']),
            ...mapState('account', ['permisos', 'loggedUser']),

            formTitle() {
                return this.id == 0
                    ? 'Nuevo caso'
                    : `Caso ${this.item.nombre === undefined ? '' : this.item.nombre
                    }`;
            },

            estadoTextColor() {
                if (this.item.estado == undefined) return 'yellow';
                else return this.item.estado.textColor == undefined ? 'black' : this.item.estado.textColor;
            },

            informeColor() {
                return this.item.estadoInforme.id == 1 ? 'green' : 'red';
            },

            desarmaderoColor() {
                if (
                    this.item.estadoId == this.TRAMITES.ANULAR ||
                    this.item.estadoId == this.TRAMITES.FINALIZADO
                )
                    return null;

                if (this.item.desarmadero.vencimientoRUDAC == null) return 'blue';

                var milliseconds = 24 * 60 * 60 * 1000;
                var rudac = new Date(
                    this.item.desarmadero.vencimientoRUDAC
                ).getTime();
                var inicio = new Date().getTime();
                var ret = Math.round((rudac - inicio) / milliseconds);

                if (ret > 0 && ret <= 20) return 'red';

                if (ret <= 30) return 'orange';

                return 'green';
            },

            estadoColor() {
                if (this.item.estado == undefined) return 'yellow';
                else return this.item.estado.color;
            },

            estadoNombre() {
                if (this.item.estado == undefined) return '';
                else return this.item.estado.nombre;
            }
        },

        created() {
            this.initializeDirect();
        },

        components: {
            autocompletaProductor,
            autocompletaTitular,
            casoTramite,
            dropboxLink,
            fecha,
            infoAuto,
            informacion,
            selectCliente,
            selectTipobaja,
            selectTramitador,
            selectUser
        }
    };
</script>
